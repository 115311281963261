body {
    /* Colors */
    --accent-color              : hsl(216, 98%, 89%);
    --primary-color             : hsl(216, 98%, 44%);
    --primary-border            : hsl(216, 98%, 80%);
    --primary-font              : rgb(255, 255, 255);
    --secondary-color           : hsl(216, 98%, 34%);

    --background-color          : var(--lightest-gray);
    --content-color             : white;
    --drop-color                : rgba(9, 30, 66, 0.5);
    --box-shadow                : rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.31) 0px 0px 1px;

    --white-color               : rgb(255, 255, 255);
    --black-color               : rgb(34, 34, 34);
    --lightest-gray             : rgb(249, 250, 252);
    --lighter-gray              : rgb(244, 245, 247);
    --light-gray                : rgb(235, 236, 240);
    --dark-gray                 : rgb(218, 220, 224);
    --darker-gray               : rgb(204, 205, 207);
    --darkest-gray              : rgb(144, 145, 147);

    --font-size                 : 14px;
    --font-color                : var(--black-color);
    --font-light                : rgb(66, 82, 90);
    --font-lighter              : rgb(94, 108, 112);

    --title-color               : rgb(27, 37, 51);
    --subtitle-color            : rgb(94, 108, 132);

    --error-color               : rgb(188, 28, 72);
    --error-text-color          : rgb(255, 0, 51);
    --success-color             : rgb(1, 163, 28);
    --warning-color             : rgb(240, 150, 9);
    --notify-color              : rgb(255, 0, 51);

    --border-width              : 1px;
    --border-radius             : 10px;
    --border-radius-small       : 6px;
    --border-radius-medium      : 15px;
    --border-radius-big         : 20px;
    --border-color-light        : rgb(240, 241, 245);
    --border-color-medium       : rgb(218, 220, 224);
    --border-color-dark         : rgb(26, 26, 35);

    --grid-size                 : 8px;
    --grid-color                : var(--lighter-gray);
    --grid-gap                  : var(--main-gap);


    /* App Variables */
    --full-height               : 100dvh;
    --main-grid-areas           : "topbar topbar" "sidebar inside";
    --main-padding              : 20px;
    --main-gap                  : 12px;

    --topbar-height             : 56px;
    --topbar-background         : var(--background-color);
    --topbar-border             : none;
    --topbar-padding            : 0 8px 0 12px;
    --topbar-logo-width         : 220px;
    --topbar-logo-max-width     : calc(100vw - 12px * 2 - 8px * 3 - 32px * 2 - 36px);
    --topbar-icon-size          : 32px;
    --topbar-icon-font          : 16px;
    --topbar-icon-color         : var(--title-color);
    --topbar-icon-background    : var(--light-gray);
    --topbar-icon-hover-color   : var(--title-color);
    --topbar-icon-hover-bg      : rgba(0, 0, 0, 0.1);
    --topbar-avatar-padding     : 4px 4px 4px 12px;
    --topbar-avatar-border      : none;
    --topbar-avatar-background  : white;

    --sidebar-width             : 70px;
    --sidebar-size              : var(--sidebar-width);
    --sidebar-background        : var(--background-color);
    --sidebar-border            : none;
    --sidebar-icon-size         : 46px;
    --sidebar-icon-font         : 22px;
    --sidebar-icon-color        : var(--title-color);
    --sidebar-icon-background   : var(--background-color);
    --sidebar-icon-hover-color  : var(--primary-color);
    --sidebar-icon-hover-bg     : var(--accent-color);

    --navigation-width          : 240px;
    --navigation-size           : var(--navigation-width);
    --navigation-background     : var(--background-color);
    --navigation-hover          : var(--light-gray);
    --navigation-selected       : var(--light-gray);
    --navigation-border         : none;
    --navigation-font-size      : 13px;

    --main-height               : 100dvh;
    --main-margin               : 8px;
    --main-radius               : var(--border-radius-big);

    --header-height             : calc(32px + var(--main-padding) * 1.5);
    --header-padding            : var(--main-padding)  var(--main-padding) calc(var(--main-padding) / 2) var(--main-padding);

    --title-font-weight         : 600;
    --title-font-size           : 22px;
    --title-letter-spacing      : 0px;

    --tabs-table                : calc(32px + var(--main-gap));
    --tabs-dialog               : 32px;
    --tabs-gap                  : 8px;

    --stats-height              : 56px;

    --filter-height             : calc(var(--input-height) + 4px * 2 + var(--main-gap));
    --filter-padding            : 4px 1px 4px 1px;
    --filter-right              : 1px;
    --filter-background         : var(--content-color);
    --filter-border             : none;

    --tooltip-color             : var(--white-color);
    --tooltip-background        : var(--primary-color);

    --table-background          : var(--lighter-gray);
    --table-hover               : var(--lightest-gray);
    --table-head-hover          : var(--light-gray);
    --table-head-resizer        : var(--dark-gray);
    --table-selected            : var(--lighter-gray);
    --table-pages               : var(--lighter-gray);
    --table-border-outer        : none;
    --table-border-inner        : 1px solid var(--border-color-light);
    --table-radius-outer        : var(--border-radius);
    --table-radius-inner        : var(--border-radius);
    --table-radius-last         : 0px;
    --table-font-size           : 13px;
    --table-font-color          : var(--font-light);
    --table-overflow            : auto;

    --none-background           : var(--background-color);
    --dropzone-background       : var(--background-color);

    --details-width             : 300px;
    --details-width-wide        : 360px;
    --details-width-large       : 440px;
    --details-size              : var(--details-width);
    --details-size-wide         : var(--details-width-wide);
    --details-size-large        : var(--details-width-large);
    --details-spacing           : 16px;
    --details-border-color      : var(--border-color-light);
    --details-title-height      : 55px;

    --dialog-header             : 64px;
    --dialog-footer             : 64px;
    --dialog-spacing            : 32px;
    --dialog-padding            : 24px;
    --dialog-radius             : var(--border-radius-big);

    --input-height              : 48px;
    --input-font                : 13px;
    --input-icon                : 16px;
    --input-label               : 18px;
    --input-vert-padding        : 6px;
    --input-horiz-padding       : 12px;
    --input-padding             : var(--input-vert-padding) var(--input-horiz-padding);
    --input-label-color         : var(--darkest-gray);
    --input-label-focus         : var(--primary-color);
    --input-disabled-color      : rgb(150, 150, 150);
    --input-border-color        : rgb(210, 211, 215);
    --input-border-disabled     : rgb(235, 236, 240);
    --input-border-hover        : rgb(26, 26, 35);
    --input-border-focus        : var(--black-color);
    --input-check-normal        : var(--black-color);
    --input-check-focus         : var(--border-color-dark);
    --input-check-checked       : var(--primary-color);
    --input-check-disabled      : var(--darkest-color);
    --input-radio-normal        : var(--primary-color);
    --input-radio-disabled      : var(--darkest-gray);
    --input-toggle-bg           : var(--darkest-gray);
    --input-toggle-checked      : var(--primary-color);
    --input-toggle-circle       : white;

    --inputicon-height          : 48px;
    --inputicon-move            : 10px;
    --inputicon-font            : 16px;
    --inputicon-icon            : 20px;

    --media-main-color          : var(--primary-color);
    --media-border-color        : var(--border-color-light);
    --media-border-width        : var(--border-width);
    --media-name-color          : var(--lighter-gray);
    --media-actions-color       : var(--lighter-gray);
    --media-trans-light         : rgb(236, 237, 238);
    --media-trans-dark          : rgb(226, 227, 228);


    /* Z Values */
    --z-input                   : 3;
    --z-backdrop                : 3000;
    --z-details                 : 3001;
    --z-sidebar                 : 3002;
    --z-navigation              : 3003;
    --z-dialog                  : 3005;
    --z-dropzone                : 3006;
    --z-menu                    : 3007;
    --z-preview                 : 3008;
    --z-result                  : 4009;
    --z-tooltip                 : 4010;


    /* Fonts */
    --main-font                 : "Inter", "Helvetica Neue", Helvetica, Arial, sans-serif;
    --title-font                : "Inter", "Helvetica Neue", Helvetica, Arial, sans-serif;
    --google-font               : "Google", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

@supports (font-variation-settings: normal) {
    body {
        --main-font  : "InterVariable", "Helvetica Neue", Helvetica, Arial, sans-serif;
        --title-font : "InterVariable", "Helvetica Neue", Helvetica, Arial, sans-serif;
    }
}

#container {
    --page-height               : calc(var(--main-height) - var(--header-height) - var(--main-padding));
    --page-height-tabs          : calc(var(--page-height) - var(--tabs-table));
    --report-height             : calc(var(--page-height) - var(--tabs-table) - var(--filter-height));

    /* Conversation Variables */
    --conversation-width        : 340px;

    /* Bubble Variables */
    --bubble-back-color         : var(--content-color);

    --bubble-mine-bg            : rgb(31, 133, 255);
    --bubble-mine-color         : white;
    --bubble-mine-footer        : rgba(255, 255, 255, 0.8);

    --bubble-your-bg            : rgb(226, 227, 228);
    --bubble-your-color         : var(--black-color);
    --bubble-your-footer        : rgba(0, 0, 0, 0.5);

    --bubble-button-color       : var(--primary-color);
    --bubble-button-hover       : white;

    /* Campaign Variables */
    --campaign-cond-width       : 40px;
    --campaign-cond-left        : -24px;
    --campaign-cond-size        : 11px;
    --campaign-cond-radius      : 4px;

    /* Provider Variables */
    --provider-width            : 700px;
    --provider-gap              : calc(var(--main-gap) * 2);

    /* WABA Templates Variables */
    --waba-width                : 320px;

    /* Widget Variables */
    --widget-width              : 400px;

    /* Flow Variables */
    --flow-actions              : 47px;
    --flow-height               : calc(var(--page-height) - var(--flow-actions));
    --flow-height-tabs          : calc(var(--page-height-tabs) - var(--flow-actions));
    --flow-nodes-size           : 40px;

    /** Automation Variables */
    --inters-height             : calc(var(--page-height) - var(--main-gap));
    --inters-height-tabs        : calc(var(--page-height-tabs) - var(--main-gap));
    --inters-action             : 480px;

    /* Document Variables */
    --document-gap              : var(--main-gap);
    --document-list-width       : 260px;
    --document-view-width       : calc(100vw - var(--sidebar-size) - var(--document-list-width) - 2 * var(--main-padding) - 4px - var(--document-gap));
}

.conversations {
    --bubble-mine-bg            : rgb(236, 243, 254);
    --bubble-mine-color         : var(--font-color);
    --bubble-mine-footer        : rgba(0, 0, 0, 0.5);

    --bubble-your-bg            : var(--lighter-gray);
    --bubble-your-color         : var(--font-color);
    --bubble-your-footer        : rgba(0, 0, 0, 0.5);

    --bubble-button-border      : var(--primary-border);
}


/** Dark Mode */
body.dark-mode {
    --accent-color              : hsl(216, 98%, 19%);
    --primary-color             : hsl(216, 98%, 58%);
    --primary-border            : hsl(216, 98%, 30%);

    --background-color          : rgb(0, 0, 0);
    --content-color             : rgb(37, 38, 40);
    --drop-color                : rgba(24, 26, 30, 0.8);

    --white-color               : black;
    --black-color               : white;
    --lightest-gray             : rgb(47, 48, 50);
    --lighter-gray              : rgb(52, 53, 55);
    --light-gray                : rgb(57, 58, 60);
    --dark-gray                 : rgb(67, 68, 70);

    --font-color                : rgb(216, 216, 217);
    --font-light                : rgb(153, 153, 154);
    --font-lighter              : rgb(133, 133, 134);
    --title-color               : rgb(255, 255, 255);
    --subtitle-color            : rgb(205, 205, 205);
    --navigation-color          : rgb(172, 172, 189);

    --border-color-light        : rgb(59, 60, 62);
    --border-color-medium       : rgb(79, 80, 82);
    --border-color-dark         : rgb(99, 100, 102);

    --input-label-color         : var(--font-lighter);
    --input-label-focus         : var(--black-color);
    --input-border-color        : rgb(59, 60, 62);
    --input-border-disabled     : rgb(29, 30, 32);
    --input-border-hover        : rgb(206, 206, 215);

    --tooltip-color             : white;
}

body.dark-mode .conversations {
    --bubble-mine-bg            : rgb(66, 63, 74);
    --bubble-mine-footer        : rgba(255, 255, 255, 0.3);

    --bubble-your-footer        : rgba(255, 255, 255, 0.3);
}


@media (max-width: 1200px) {
    body {
        --details-size          : 0px;
        --details-size-wide     : 0px;
        --details-size-large    : 0px;
    }
}

@media (max-width: 1000px) {
    body {
        --main-margin           : 0px;
        --main-radius           : 0px;
        --grid-gap              : 0px;

        --sidebar-width         : 180px;
        --sidebar-size          : 0px;
        --navigation-size       : 0px;

        --header-height         : calc(32px + var(--main-padding));
        --header-padding        : calc(var(--main-padding) / 2) var(--main-padding);
    }
}

@media (max-width: 700px) {
    body {
        --conversation-width    : 0px;
        --document-list-width   : 0px;
        --document-gap          : 0px;
    }
}

@media (max-width: 500px) {
    body {
        --main-padding          : 12px;
    }
}
