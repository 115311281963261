.icon-access:before { content: "\ea01"; }
.icon-account:before { content: "\ea02"; }
.icon-action-log:before { content: "\ea03"; }
.icon-action:before { content: "\ea04"; }
.icon-active:before { content: "\ea05"; }
.icon-ad:before { content: "\ea06"; }
.icon-add:before { content: "\ea07"; }
.icon-addon:before { content: "\ea08"; }
.icon-address:before { content: "\ea09"; }
.icon-admin:before { content: "\ea0a"; }
.icon-alert-type:before { content: "\ea0b"; }
.icon-amount:before { content: "\ea0c"; }
.icon-announcement:before { content: "\ea0d"; }
.icon-appearance:before { content: "\ea0e"; }
.icon-assign:before { content: "\ea0f"; }
.icon-assistant-log:before { content: "\ea10"; }
.icon-assistant-model:before { content: "\ea11"; }
.icon-assistant:before { content: "\ea12"; }
.icon-attachment:before { content: "\ea13"; }
.icon-automation:before { content: "\ea14"; }
.icon-back:before { content: "\ea15"; }
.icon-banner:before { content: "\ea16"; }
.icon-block:before { content: "\ea17"; }
.icon-bold:before { content: "\ea18"; }
.icon-booking:before { content: "\ea19"; }
.icon-bubble:before { content: "\ea1a"; }
.icon-campaign:before { content: "\ea1b"; }
.icon-cancelled:before { content: "\ea1c"; }
.icon-category:before { content: "\ea1d"; }
.icon-channel:before { content: "\ea1e"; }
.icon-check:before { content: "\ea1f"; }
.icon-checkbox:before { content: "\ea20"; }
.icon-checkedbox:before { content: "\ea21"; }
.icon-checkedin:before { content: "\ea22"; }
.icon-checkedout:before { content: "\ea23"; }
.icon-circle:before { content: "\ea24"; }
.icon-clear:before { content: "\ea25"; }
.icon-client:before { content: "\ea26"; }
.icon-close:before { content: "\ea27"; }
.icon-closed:before { content: "\ea28"; }
.icon-code:before { content: "\ea29"; }
.icon-column:before { content: "\ea2a"; }
.icon-completed:before { content: "\ea2b"; }
.icon-condition:before { content: "\ea2c"; }
.icon-connector-log:before { content: "\ea2d"; }
.icon-connector:before { content: "\ea2e"; }
.icon-contact-date:before { content: "\ea2f"; }
.icon-contact:before { content: "\ea30"; }
.icon-conversation:before { content: "\ea31"; }
.icon-copy:before { content: "\ea32"; }
.icon-country:before { content: "\ea33"; }
.icon-create:before { content: "\ea34"; }
.icon-currency:before { content: "\ea35"; }
.icon-dashboard:before { content: "\ea36"; }
.icon-delete:before { content: "\ea37"; }
.icon-details:before { content: "\ea38"; }
.icon-directory:before { content: "\ea39"; }
.icon-discord:before { content: "\ea3a"; }
.icon-document-url:before { content: "\ea3b"; }
.icon-document:before { content: "\ea3c"; }
.icon-documents:before { content: "\ea3d"; }
.icon-double-check:before { content: "\ea3e"; }
.icon-down:before { content: "\ea3f"; }
.icon-draft:before { content: "\ea40"; }
.icon-drag:before { content: "\ea41"; }
.icon-edit:before { content: "\ea42"; }
.icon-email-queue:before { content: "\ea43"; }
.icon-email:before { content: "\ea44"; }
.icon-emoji:before { content: "\ea45"; }
.icon-error-log:before { content: "\ea46"; }
.icon-error:before { content: "\ea47"; }
.icon-expand:before { content: "\ea48"; }
.icon-export:before { content: "\ea49"; }
.icon-facebook:before { content: "\ea4a"; }
.icon-fetch:before { content: "\ea4b"; }
.icon-field:before { content: "\ea4c"; }
.icon-file-audio:before { content: "\ea4d"; }
.icon-file-code:before { content: "\ea4e"; }
.icon-file-document:before { content: "\ea4f"; }
.icon-file-pdf:before { content: "\ea50"; }
.icon-file-presentation:before { content: "\ea51"; }
.icon-file-spreadsheet:before { content: "\ea52"; }
.icon-file-text:before { content: "\ea53"; }
.icon-file-video:before { content: "\ea54"; }
.icon-file-zip:before { content: "\ea55"; }
.icon-file:before { content: "\ea56"; }
.icon-filter:before { content: "\ea57"; }
.icon-first:before { content: "\ea58"; }
.icon-flow:before { content: "\ea59"; }
.icon-followup:before { content: "\ea5a"; }
.icon-format:before { content: "\ea5b"; }
.icon-forward:before { content: "\ea5c"; }
.icon-forze:before { content: "\ea5d"; }
.icon-gestion-bos:before { content: "\ea5e"; }
.icon-gmail:before { content: "\ea5f"; }
.icon-google-sheets:before { content: "\ea60"; }
.icon-google:before { content: "\ea61"; }
.icon-help:before { content: "\ea62"; }
.icon-hide:before { content: "\ea63"; }
.icon-hospitality-date:before { content: "\ea64"; }
.icon-hospitality-move:before { content: "\ea65"; }
.icon-hospitality-smdr:before { content: "\ea66"; }
.icon-hospitality:before { content: "\ea67"; }
.icon-hotel:before { content: "\ea68"; }
.icon-id:before { content: "\ea69"; }
.icon-image:before { content: "\ea6a"; }
.icon-import:before { content: "\ea6b"; }
.icon-inbox:before { content: "\ea6c"; }
.icon-info:before { content: "\ea6d"; }
.icon-instagram:before { content: "\ea6e"; }
.icon-interpret:before { content: "\ea6f"; }
.icon-invite:before { content: "\ea70"; }
.icon-italic:before { content: "\ea71"; }
.icon-jump:before { content: "\ea72"; }
.icon-language:before { content: "\ea73"; }
.icon-last:before { content: "\ea74"; }
.icon-link:before { content: "\ea75"; }
.icon-linkedin:before { content: "\ea76"; }
.icon-list:before { content: "\ea77"; }
.icon-login:before { content: "\ea78"; }
.icon-logout:before { content: "\ea79"; }
.icon-logs:before { content: "\ea7a"; }
.icon-mastodon:before { content: "\ea7b"; }
.icon-media:before { content: "\ea7c"; }
.icon-menu:before { content: "\ea7d"; }
.icon-mercado-libre:before { content: "\ea7e"; }
.icon-merge:before { content: "\ea7f"; }
.icon-message:before { content: "\ea80"; }
.icon-messenger:before { content: "\ea81"; }
.icon-minus:before { content: "\ea82"; }
.icon-money:before { content: "\ea83"; }
.icon-monitoring:before { content: "\ea84"; }
.icon-more:before { content: "\ea85"; }
.icon-move:before { content: "\ea86"; }
.icon-mute:before { content: "\ea87"; }
.icon-name:before { content: "\ea88"; }
.icon-next:before { content: "\ea89"; }
.icon-nights:before { content: "\ea8a"; }
.icon-none:before { content: "\ea8b"; }
.icon-note:before { content: "\ea8c"; }
.icon-notification:before { content: "\ea8d"; }
.icon-nubila:before { content: "\ea8e"; }
.icon-observations:before { content: "\ea8f"; }
.icon-omnitec:before { content: "\ea90"; }
.icon-open:before { content: "\ea91"; }
.icon-openai:before { content: "\ea92"; }
.icon-options:before { content: "\ea93"; }
.icon-order:before { content: "\ea94"; }
.icon-origin:before { content: "\ea95"; }
.icon-outlook:before { content: "\ea96"; }
.icon-partner:before { content: "\ea97"; }
.icon-password:before { content: "\ea98"; }
.icon-pause:before { content: "\ea99"; }
.icon-paused:before { content: "\ea9a"; }
.icon-personalize:before { content: "\ea9b"; }
.icon-phone-number:before { content: "\ea9c"; }
.icon-phone:before { content: "\ea9d"; }
.icon-pinterest:before { content: "\ea9e"; }
.icon-plan:before { content: "\ea9f"; }
.icon-plus:before { content: "\eaa0"; }
.icon-prev:before { content: "\eaa1"; }
.icon-print:before { content: "\eaa2"; }
.icon-product:before { content: "\eaa3"; }
.icon-profile:before { content: "\eaa4"; }
.icon-progress:before { content: "\eaa5"; }
.icon-provider-log:before { content: "\eaa6"; }
.icon-province:before { content: "\eaa7"; }
.icon-publish:before { content: "\eaa8"; }
.icon-qualification-bad:before { content: "\eaa9"; }
.icon-qualification-excellent:before { content: "\eaaa"; }
.icon-qualification-good:before { content: "\eaab"; }
.icon-qualification:before { content: "\eaac"; }
.icon-query-log:before { content: "\eaad"; }
.icon-questionnaire:before { content: "\eaae"; }
.icon-queue:before { content: "\eaaf"; }
.icon-quora:before { content: "\eab0"; }
.icon-radio-off:before { content: "\eab1"; }
.icon-radio-on:before { content: "\eab2"; }
.icon-reddit:before { content: "\eab3"; }
.icon-reply:before { content: "\eab4"; }
.icon-report:before { content: "\eab5"; }
.icon-request:before { content: "\eab6"; }
.icon-resolved:before { content: "\eab7"; }
.icon-restart:before { content: "\eab8"; }
.icon-return:before { content: "\eab9"; }
.icon-room:before { content: "\eaba"; }
.icon-sale:before { content: "\eabb"; }
.icon-save:before { content: "\eabc"; }
.icon-schedule:before { content: "\eabd"; }
.icon-search:before { content: "\eabe"; }
.icon-send:before { content: "\eabf"; }
.icon-settings:before { content: "\eac0"; }
.icon-simulate:before { content: "\eac1"; }
.icon-skype:before { content: "\eac2"; }
.icon-sms:before { content: "\eac3"; }
.icon-snapchat:before { content: "\eac4"; }
.icon-star-outline:before { content: "\eac5"; }
.icon-star:before { content: "\eac6"; }
.icon-start:before { content: "\eac7"; }
.icon-status:before { content: "\eac8"; }
.icon-store:before { content: "\eac9"; }
.icon-strikethrough:before { content: "\eaca"; }
.icon-subscription:before { content: "\eacb"; }
.icon-summary:before { content: "\eacc"; }
.icon-support:before { content: "\eacd"; }
.icon-tag:before { content: "\eace"; }
.icon-team:before { content: "\eacf"; }
.icon-telegram:before { content: "\ead0"; }
.icon-template:before { content: "\ead1"; }
.icon-ticket-closed:before { content: "\ead2"; }
.icon-ticket-open:before { content: "\ead3"; }
.icon-ticket-type:before { content: "\ead4"; }
.icon-ticket:before { content: "\ead5"; }
.icon-tiendanube:before { content: "\ead6"; }
.icon-tiktok:before { content: "\ead7"; }
.icon-time:before { content: "\ead8"; }
.icon-title:before { content: "\ead9"; }
.icon-tumblr:before { content: "\eada"; }
.icon-twitch:before { content: "\eadb"; }
.icon-twitter:before { content: "\eadc"; }
.icon-unmute:before { content: "\eadd"; }
.icon-unread:before { content: "\eade"; }
.icon-up:before { content: "\eadf"; }
.icon-user:before { content: "\eae0"; }
.icon-variable:before { content: "\eae1"; }
.icon-video:before { content: "\eae2"; }
.icon-view:before { content: "\eae3"; }
.icon-wait:before { content: "\eae4"; }
.icon-waitlist:before { content: "\eae5"; }
.icon-webhook:before { content: "\eae6"; }
.icon-wechat:before { content: "\eae7"; }
.icon-whatsapp:before { content: "\eae8"; }
.icon-white-email:before { content: "\eae9"; }
.icon-widget:before { content: "\eaea"; }
.icon-youtube:before { content: "\eaeb"; }

